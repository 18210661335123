<footer class="footer-partial">
    <div class="content">  
        <div class="container">
            <div class="row">
                <div class="col-md-3">
                    <modulo posicao="contato" template="footer"></modulo>
                    <modulo posicao="rede-social" template="footer"></modulo>
                </div>
                <div class="col-md-3">
                    <div class="menu">
                        <h3>Ajuda</h3>
                        <ul>
                            <li>
                                <a href="javascript::void();" title="Dúvidas Frequentes" (click)="openDuvidas()">
                                    Dúvidas Frequentes
                                </a>
                            </li>
                            <li (click)="toSection('#section-contato')">
                                <a href="javascript::void();" title="Fale Conosco">
                                    Fale Conosco
                                </a>
                            </li>
                        </ul>
                    </div>
                </div>  
                <div class="col-md-6">
                    <div class="content-one">
                        <modulo posicao="whatsapp" class="content-whatsapp"></modulo>
                        <modulo posicao="logo-footer"></modulo>
                    </div>
                </div>  
            </div>    
        </div>   
    </div>
    <div class="copy">
        <div class="container">
            <p>
                <span>© Vasto Representações 2023 - Desenvolvido por</span>
                <a href="https://digitalmovement.com.br" title="Digita Movement - Agência Digital">
                    <span>Digital Movement</span>
                    <img src="/assets/logos/digital-movement.svg" alt="Digita Movement - Soluções em Tecnologia" width="20px" />
                </a>
            </p>
        </div>    
    </div>    
</footer>
