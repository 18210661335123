import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { HomePage } from './pages/home-page/home-page.component';
import { Modulo } from './modulos/modulo/modulo.component';
import { ModuloLogo } from './modulos/logo/logo.component';
import { MenuComponent } from './components/menu/menu.component';
import { ModuloService } from './services/modulo.service';
import { DataService } from './services/data.service';
import { AppService } from './services/app.service';
import { HttpClientModule } from '@angular/common/http';
import { HeaderComponent } from './components/header/header.component';
import { BannerSliderComponent } from './modulos/banner-slider/banner-slider.component';
import { RedeSocialComponent } from './modulos/rede-social/rede-social.component';
import { NewsletterComponent } from './modulos/newsletter/newsletter.component';
import { WhatsappComponent } from './modulos/whatsapp/whatsapp.component';
import { LazyLoadImageModule } from 'ng-lazyload-image'; //@3.4.2
import { DeviceDetectorModule } from 'ngx-device-detector';
import { FormsModule } from '@angular/forms';
import { FooterComponent } from './components/footer/footer.component';
import { FaleConoscoComponent } from './modulos/fale-conosco/fale-conosco.component';
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { ParceirosComponent } from './modulos/parceiros/parceiros.component';  
import { MatIconModule } from '@angular/material/icon';
import { MatDialogModule } from '@angular/material/dialog';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 
import { ModalInfo } from './components/modal/modal-info/modal-info.component';
import { LoaderComponent } from './components/loader/loader/loader.component';
import { SafeHtmlPipe } from './pipes/safe-html.pipe';
import { GtagService } from './services/gtag.service';
import { PageErrorComponent } from './components/error/page-error/page-error.component';
import { BreadcrumbsComponent } from './components/breadcrumbs/breadcrumbs.component';
import { ModalSugestao } from './components/modal/modal-sugestao/modal-sugestao.component';
import { TopoPartialComponent } from './components/partial/topo-partial/topo-partial.component';
import { EnderecoModuloComponent } from './modulos/endereco-modulo/endereco-modulo.component';
import { ClienteModuloComponent } from './modulos/cliente-modulo/cliente-modulo.component';
import { ContatoModuloComponent } from './modulos/contato-modulo/contato-modulo.component';
import { MaskDirective } from './directives/mask.directive';
import { LogoFooterModuloComponent } from './modulos/logo-footer-modulo/logo-footer-modulo.component';
import { MenuFooterPartialComponent } from './components/partial/menu-footer-partial/menu-footer-partial.component';
import { VisaoModuloComponent } from './modulos/visao-modulo/visao-modulo.component';
import { QuemSomosComponent } from './modulos/quem-somos/quem-somos.component';
import { ModalDuvidaComponent } from './components/modal/modal-duvida/modal-duvida.component';
import { RecaptchaV3Module, ReCaptchaV3Service, RECAPTCHA_V3_SITE_KEY } from 'ng-recaptcha';

@NgModule({
    declarations: [
        AppComponent,
        HomePage,
        Modulo,
        ModuloLogo,
        MenuComponent,
        HeaderComponent,
        BannerSliderComponent,
        RedeSocialComponent,
        NewsletterComponent,
        WhatsappComponent,
        FooterComponent,
        FaleConoscoComponent,
        PhoneMaskDirective,
        ParceirosComponent,
        ModalInfo,
        LoaderComponent,
        SafeHtmlPipe,
        PageErrorComponent,
        BreadcrumbsComponent,
        ModalSugestao,
        TopoPartialComponent,
        EnderecoModuloComponent,
        ClienteModuloComponent,
        ContatoModuloComponent,
        MaskDirective,
        LogoFooterModuloComponent,
        MenuFooterPartialComponent,
        VisaoModuloComponent,
        QuemSomosComponent,
        ModalDuvidaComponent
    ],
    imports: [
        BrowserModule,
        BrowserAnimationsModule,
        AppRoutingModule,
        HttpClientModule,
        LazyLoadImageModule,
        DeviceDetectorModule.forRoot(),
        FormsModule,
        MatIconModule,
        MatDialogModule,
        RecaptchaV3Module
    ],
    providers: [
        ModuloService,
        DataService,
        AppService,
        GtagService,
        {provide: RECAPTCHA_V3_SITE_KEY,useValue: "6LcuXfUjAAAAAN6Nt98idR7Yc3KM1PdWygf-AQvB" }
    ],
    bootstrap: [AppComponent]
})
export class AppModule{ }
