<div class="moda-duvida">
    <div class="header">
        <h3>Dúvidas Frequentes</h3>
        <div class="icon" (click)="dialog.close()">
            <i class="fa-solid fa-xmark"></i>
        </div>
    </div>
    <div class="content">
        <div class="duvidas">
            <div class="duvida" *ngFor="let d of dataDuvida">
                <div class="title" (click)="_show(d)">
                    <span>{{d.titulo}}</span>
                    <div class="icon">  
                        <i class="fa-solid fa-chevron-down" *ngIf="!d.show"></i>
                        <i class="fa-solid fa-chevron-up" *ngIf="d.show"></i>
                    </div>
                </div>
                <div class="content" *ngIf="d.show">
                    <article [innerHtml]="d.texto"></article>
                </div>
            </div>
        </div>
    </div>
</div>