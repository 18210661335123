import { Component, OnInit } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { ModalDuvidaComponent } from '../modal/modal-duvida/modal-duvida.component';

declare var $:any;

@Component({
  selector: 'footer-partial',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private dialog: MatDialog
  ) { }

  /**
   * 
   * To section
   * 
   */
  toSection(id:any){

    var top = $(id).offset().top; 
          
    $('html, body').stop().animate({ 
      scrollTop: top-100 
    },500);


  }
  /**
   * 
   * Open
   * 
   */
  openDuvidas(){

    this.dialog.open(ModalDuvidaComponent,{
      width: "800px"
    }); 

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
