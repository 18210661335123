import { Component, OnInit, Input, ElementRef, ViewChild, AfterContentInit } from '@angular/core';

declare var $:any;

@Component({
  selector: 'modulo-parceiros',
  templateUrl: './parceiros.component.html',
  styleUrls: ['./parceiros.component.scss']
})
export class ParceirosComponent implements OnInit {

  @ViewChild("slider") slider:ElementRef;
  @Input("data") data:any = null; 
  public parcerias = [];

  constructor() { }
  
  /**
   * 
   * Seta as parecerias
   * 
   */
  setParcerias(){

    this.parcerias.push({
      nome: "Digital Movement",
      site: "https://digitalmovement.com.br",
      logo: "images/parceria/digital-movement.svg"
    });
    this.parcerias.push({
      nome: "Penha Solutions",
      site: "http://penhasolutions.com.br",
      logo: "images/parceria/penha-solutions.png"
    });

  }
  /**
   * 
   * Init
   * 
   */
  init(){  

    $(this.slider.nativeElement).slick({ 
      infinite: true,
      slidesToShow: 3,
      dots: false, 
      arrows: false,
      centerMode: false,
      autoplay: false,
      centerPadding: '30px',
      responsive: [{
          breakpoint: 1224,
          settings: {
            slidesToShow: 3,  
          }
      },{
          breakpoint: 1024,
          settings: {
          slidesToShow: 2,
        }
      },
      {
          breakpoint: 768,
          settings: {
              slidesToShow: 1,

          }
      },
      {
          breakpoint: 540,
          settings: {
            slidesToShow: 1,
            centerPadding: '0',
          }
      }
    ]
    
    }); 
    $(this.slider.nativeElement).slick('refresh'); 

  }
  /**
   * 
   * Next
   * 
   */
  prev(){ 

    $(this.slider.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.slider.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * Incializa as Funções
   * 
   */
  ngOnInit(): void {
    this.setParcerias();
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.init();
  }

}
