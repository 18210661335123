import { Component, Input, OnInit } from '@angular/core';
import { ReCaptchaV3Service } from 'ng-recaptcha';
import { DeviceDetectorService } from 'ngx-device-detector';
import { ApiService } from 'src/app/services/api.service';
import { AppService } from 'src/app/services/app.service';

@Component({
  selector: 'contato-modulo',
  templateUrl: './contato-modulo.component.html',
  styleUrls: ['./contato-modulo.component.scss']
})
export class ContatoModuloComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;
  public prefixo              = "web";
  public numero               = "";
  public dataItem:any = {};
  public loader       = false;

  constructor(
    private deviceService: DeviceDetectorService,
    private api: ApiService,
    private app: AppService,
    private recaptchaV3Service: ReCaptchaV3Service
  ) { }

  _clickInstagram(){

  }
  /**
   * 
   * Open WhatsApp
   * 
   */
  _click(){

    window.open("https://"+this.prefixo+".whatsapp.com/send?phone="+this.numero,"__blank");
    
  }
  /***
   * 
   * To Facebook
   * 
   */
  toFacebook(){

    window.open(this.data.params.facebook,"_blank");

  }
  /***
   * 
   * To Facebook
   * 
   */
  toEmail(){

    window.open("mailto:"+this.data.params.email,"_blank");

  }
  /***
   * 
   * To Facebook
   * 
   */
   toInstagram(){

    window.open(this.data.params.instagram,"_blank");

  }
  /***
   * 
   * To Facebook
   * 
   */
  toPhone(){  

    window.open("tel:"+this.data.params.celular,"_self");

  }
  /**
   * 
   * Seta o número
   * 
   */
   setNumero(){
    
    let telefone = this.data.params.whatsapp.replace("(","").replace(")","").replace("-","").replace(" ","");

    this.numero = "55"+telefone;


  }
  /**
   * 
   * Init data item
   * 
   */
  initDataItem(){

    this.dataItem = {
      nome: "",
      email: "",
      telefone: "",
      apelido: "modulo-contato"
    }

  }
  /**
   * 
   * Send
   * 
   */
  send(){

    try{

      this.loader = true;

      this.recaptchaV3Service.execute('importantAction').subscribe((token) => {

        this.dataItem.token = token;

        this.api.contato().contato(this.dataItem).subscribe((response) => {

          this.loader = false;

          if(response.status == 1){

            this.app.info("Obrigado pelo seu contato. Em breve falaremos com você.");
            this.initDataItem();

          }else{

            let error   = this.api.formatError(response);

            this.app.info(error.message);

          }

        },(response) => {

          this.loader = false;
          let error   = this.api.formatError(response);

          this.app.info(error.message);

        })


      },(response) => {

        this.loader = false;

      });

    }catch(e){

      this.loader = false;

    }

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit(): void {
    this.setNumero();
    this.prefixo = this.deviceService.isDesktop() ? "web" : "api";
    this.initDataItem();
  }

}
