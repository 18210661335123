<div class="modulo modulo-parcero">
    <div class="container">
        <div class="content">
            <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
                <div class="container">
                    <h2 class="wow fadeIn" data-wow-delay="1s">{{data.titulo}}</h2>
                </div>     
            </div>    
            <div class="modulo-content">
                <div class="container"> 
                    <section class="section list-parceiros" #slider> 
                        <div class="slider-item" *ngFor="let p of data.parceiros;let i=index;">
                            <div class="parceiro parceiro-{{i}}">
                                <div class="capa">
                                    <figure class="imagem-logo" *ngIf="p.imagem_logo != null && p.imagem_logo != ''">
                                        <img src="/images/modulo/parceiro/imagem-logo/{{p.imagem_logo}}" [alt]="p.nome" />
                                    </figure>
                                    <figure class="logo">
                                        <img src="/images/modulo/parceiro/{{p.logo}}" [alt]="p.nome" />
                                    </figure>
                                </div>
                                <div class="titulo">
                                    <h3>{{p.titulo}}</h3>
                                </div>
                            </div> 
                        </div>
                    </section>
                    <div class="slide-navigate-item prev template-2" (click)="prev()">
                        <div class="content">
                            <i class="material-icons">navigate_before</i>
                        </div>
                    </div>
                    <div class="slide-navigate-item next template-2" (click)="next()">
                        <div class="content">
                            <i class="material-icons">navigate_next</i>
                        </div>
                    </div> 
                    <section class="section section-imagem">
                        <figure>
                            <img class="animated infinite pulse" src="/images/modulo/parceiro/imagem/{{data.params.imagem}}" alt="Tecnologia" />
                        </figure>
                    </section>
                </div>    
            </div>  
        </div>
    </div>  
</div>   
