<div class="modulo modulo-visao paralax" [ngStyle]="{'background-image': 'url(' + photo + ')'}">
    <div class="modulo-content">
        <div class="container"> 
            <div class="row">
                <div class="col-md-3"></div>
                <div class="col-md-9">
                    <h3 *ngIf="data.mostrar_titulo" class="wow slideInDown">{{data.titulo}}</h3>
                </div>
            </div>    
            <div class="row">
                <div class="col-md-3">
                    <span class="aspas wow slideInLeft">“</span>
                </div>
                <div class="col-md-9">
                    <article class="article-strong wow fadeIn" [innerHtml]="data.params.texto | safeHtml"></article>
                </div>    
            </div> 
        </div>   
    </div>  
    <div class="bg"></div>  
</div>
<div class="linha-item">
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
    <div class="item"></div>
</div>
<div class="linha"></div>
 