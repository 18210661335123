<div class="modulo modulo-contato" *ngIf="template == null">
    <div class="barra">
        <img src="/images/template/barra.svg" alt="Barra" width="100%" />
    </div>
    <div class="modulo-content">
        <div class="container">
            <section class="section section-list-contact">
                <h3>MANDA UM OI PRA GENTE?</h3>
                <ul>
                    <li>
                        <div class="item">
                            <div class="icon">
                                <i class="fa-brands fa-whatsapp"></i>
                            </div>
                            <div class="text">
                                <strong>WhatsApp</strong>
                                <span>{{data.params.whatsapp}}</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item item-tel" (click)="toPhone()">
                            <div class="icon">
                                <i class="fa-solid fa-phone"></i>
                            </div>
                            <div class="text">
                                <strong>Telefone</strong>
                                <span>{{data.params.celular}}</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item" (click)="toEmail()">
                            <div class="icon">
                                <i class="fa-solid fa-envelope"></i>
                            </div>
                            <div class="text">
                                <strong>E-mail</strong>
                                <span>{{data.params.email}}</span>
                            </div>
                        </div>
                    </li>
                    <li>
                        <div class="item" (click)="toInstagram()">
                            <div class="icon">
                                <i class="fa-brands fa-square-instagram"></i>
                            </div>
                            <div class="text">
                                <strong>Instagram</strong>
                                <span>{{data.params.instagram_conta}}</span>
                            </div>
                        </div>
                    </li>
                </ul>
            </section>
            <section class="section section-form">
                <div class="box">
                    <h3>{{data.params.form_titulo}}</h3>
                    <form class="form form-custom" (submit)="send()">
                        <div class="form-group">
                            <label>NOME:</label>
                            <input type="text" [(ngModel)]="dataItem.nome" name="nome" placeholder="Digite seu nome completo" />
                        </div>
                        <div class="form-group">
                            <label>E-MAIL:</label>
                            <input type="text" [(ngModel)]="dataItem.email" name="email" placeholder="Digite seu e-mail" />
                        </div>
                        <div class="form-group">
                            <label>TELEFONE:</label>
                            <input type="text" mask="(99) 99999-9999" [(ngModel)]="dataItem.telefone" name="telefone" placeholder="Digite seu número de telefone"  />
                        </div>
                        <div class="form-group">
                            <button class="btn-send">
                                ENVIAR  
                            </button>
                        </div>
                    </form>
                </div>
                <div class="box">
                    <figure class="img-equipe wow slideInRight">
                        <img src="/images/modulo/contato/{{data.params.imagem}}" alt="Equipe Vasto" width="400px"/>
                    </figure>
                </div>
            </section>
        </div>
        <div class="barra">
            <img src="/images/template/barra-footer.svg" alt="Barra" width="100%" />
        </div>
    </div>
    <loader *ngIf="loader"></loader>
</div>  
<div class="modulo-contato-footer" *ngIf="template == 'footer'">
    <h3>Contato</h3>
    <div class="itens">
        <div class="item">
            <a href="tel:{{data.params.celular}}">{{data.params.celular}}</a> 
        </div>
        <div class="item">
            <a href="mailto:{{data.params.email}}">{{data.params.email}}</a> 
        </div>
    </div>
</div>
