<div class="module address-module">
    <div class="module-title">
        <h3>{{data.params.cidade}}</h3>
    </div>
    <div class="module-content">
        <p>{{data.params.rua}}, {{data.params.numero}} {{data.params.complemento}}, {{data.params.bairro}} - {{data.params.cep}}</p>
        <p>{{data.params.cidade}}</p>
        <div class="ver-mapa">
            <a [href]="data.params.link_mapa" title="Mapa" target="_blank">
                Ver no Mapa
            </a>
        </div>    
    </div>    
</div>    