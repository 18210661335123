<div class="modulo menu-footer-partial">
    <div class="modulo-title">
        <h3>Links Úteis</h3>
    </div> 
    <div class="modulo-content">
        <ul>
            <li>
                <a routerLink="/contato" title="Contato">
                    Contato
                </a>    
            </li>    
            <li>
                <a routerLink="/dizimos-e-ofertas" title="Dízimos e Ofertas">
                    Dízimos e Ofertas
                </a> 
            </li>
            <li>
                <a routerLink="/greenhouse-school" title="Greenhouse Scholl - Escola de Capacitação">
                    Greenhouse School 
                </a> 
            </li>
            <li>
                <a routerLink="/conexoes" title="Grupos de Conexões">
                    Grupos de Conexões
                </a> 
            </li>
        </ul>    
    </div>    
</div>    