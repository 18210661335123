import { Component, OnInit, Input } from '@angular/core';

@Component({
  selector: 'modulo-rede-social',
  templateUrl: './rede-social.component.html',
  styleUrls: ['./rede-social.component.scss']
})
export class RedeSocialComponent implements OnInit {

  @Input("data") data;
  @Input("template") template;

  constructor() { }

  /**
   * 
   * Iniciliza as Funções
   * 
   */
  ngOnInit(){}

}
