import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'menu-footer-partial',
  templateUrl: './menu-footer-partial.component.html',
  styleUrls: ['./menu-footer-partial.component.scss']
})
export class MenuFooterPartialComponent implements OnInit {

  constructor() { }

  /***
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
