<div class="module-whatsapp" (click)="open()">
  <div class="content">
    <div class="icon">
        <i class="fa-brands fa-whatsapp"></i>
    </div>
    <div class="text">  
        <span>Manda um oi?</span>
    </div>
  </div>  
</div>  
