<div class="modulo modulo-quem-somos">
    <div class="container">
        <div class="content">
            <section class="section section-icons">
                <ul>
                    <li>
                        <img src="/images/icons/riqueza.png" alt="Riqueza" width="100px" class="wow slideInLeft" data-wow-delay="500ms" />
                    </li>
                    <li>
                        <img src="/images/icons/maos.png" alt="Parceria" width="100px" class="wow slideInUp" data-wow-delay="1s"  />
                    </li>
                    <li>
                        <img src="/images/icons/comunicacao.png" alt="Comunicação" width="100px" class="wow slideInDown" data-wow-delay="1s"  />
                    </li>
                    <li>
                        <img src="/images/icons/foguete.png" alt="Resultado" width="100px" class="wow slideInUp" data-wow-delay="1s" />
                    </li>
                    <li>
                        <img src="/images/icons/trofeu.png" alt="Resultado" width="100px" class="wow slideInRight" data-wow-delay="500ms"  />
                    </li>
                </ul>
            </section>
            <section class="section section-about">
                <div class="section-title">
                    <h2>
                       <span>{{data.params.chamada_titulo_01}}</span>
                        <img class="animated infinite pulse" data-wow-delay="1s"  src="/images/modulo/quem-somos/{{data.params.chamada_logo}}" alt="Vasto Representações" />
                       <span>{{data.params.chamada_titulo_02}}</span>
                    </h2>
                </div>
                <div class="section-content">
                    <div class="box-texto">
                        <div class="box"> 
                            <span class="title wow slideInUp" data-wow-delay="500ms">{{data.params.texto_titulo}}</span>
                            <article class="description wow slideInDown" data-wow-delay="700ms" [innerHtml]="data.params.texto"></article>
                        </div>
                        <div class="box">
                            <figure class="wow bounce" data-wow-delay="1s" data-wow-iteration="3">
                                <img src="/images/modulo/quem-somos/{{data.params.texto_imagem}}" alt="Vasto Quem Somos" width="500px" />
                            </figure>
                        </div>
                    </div>
                </div>  
            </section>
            <section class="section section-work">
                <div class="box-texto">
                    <div class="box">
                        <div class="box-content">
                            <span class="title wow slideInDown">{{data.params.atuacao_titulo}}</span> 
                            <article class="description wow slideInLeft" [innerHtml]="data.params.atuacao_texto"></article>
                        </div>
                    </div>
                    <div class="box">
                        <figure class="wow backInDown" data-wow-delay="1s">
                            <img src="/images/modulo/quem-somos/{{data.params.atuacao_imagem}}" alt="Vasto - Onde Atuamos" width="700px" />
                        </figure>
                    </div>
                </div>
            </section>
        </div>
    </div>
</div>

