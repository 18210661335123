<div *ngFor="let m of modulos" [ngSwitch]="m.tag">
  <div *ngSwitchCase="'logo'"> 
    <modulo-logo [data]="m" [classCustom]="classCustom"></modulo-logo>  
  </div>
  <div *ngSwitchCase="'banner-slider'">  
    <modulo-banner-slider [data]="m"></modulo-banner-slider>
  </div>
  <div *ngSwitchCase="'rede-social'"> 
    <modulo-rede-social [data]="m" [template]="template"></modulo-rede-social>
  </div>
  <div *ngSwitchCase="'newsletter'"> 
    <modulo-newsletter [data]="m" [template]="template"></modulo-newsletter>
  </div>
  <div *ngSwitchCase="'whatsapp'"> 
    <modulo-whatsapp [data]="m" [template]="template"></modulo-whatsapp>
  </div>
  <div *ngSwitchCase="'fale-conosco'">   
    <modulo-fale-conosco [data]="m" [template]="template"></modulo-fale-conosco>
  </div>
  <div *ngSwitchCase="'parceiros'">   
    <modulo-parceiros [data]="m"></modulo-parceiros>
  </div>
  <div *ngSwitchCase="'endereco'">       
    <endereco-modulo [data]="m" [template]="template"></endereco-modulo> 
  </div>
  <div *ngSwitchCase="'contato'">       
    <contato-modulo [data]="m" [template]="template"></contato-modulo> 
  </div>
  <div *ngSwitchCase="'logo-footer'">       
    <logo-footer-modulo [data]="m"></logo-footer-modulo> 
  </div>
  <div *ngSwitchCase="'quem-somos'">        
    <modulo-quem-somos [data]="m"></modulo-quem-somos> 
  </div>
</div>
  
  
  