import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { DataService } from 'src/app/services/data.service';

@Component({
  selector: 'modal-duvida',
  templateUrl: './modal-duvida.component.html',
  styleUrls: ['./modal-duvida.component.scss']
})
export class ModalDuvidaComponent implements OnInit {

  public dataDuvida = null;

  constructor(
    public dialog: MatDialogRef<ModalDuvidaComponent>,
    @Inject(MAT_DIALOG_DATA) public data,
    private dataService: DataService
  ) { 


  }
  /**
   * 
   * Set data
   * 
   */
  setData(){

    this.dataDuvida = this.dataService.getDuvidas();

  }
  /**
   * 
   * Show
   * 
   */
  _show(d){

    d.show = d.show ? false : true;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setData();
  }

}
