<header #heade class="animated-two fadeIn">
  <div class="content-topo">
    <div class="container">
      <div class="row">
        <div class="col-md-3">
          <modulo class="logo wow slideInUp" data-wow-delay="1s" posicao="logo"></modulo> 
        </div>
        <div class="col-md-9">
          <div class="work wow slideInDown" data-wow-delay="1s" (click)="toSection('#section-contato')">
            <div class="box">
              <img src="/images/icons/people.svg" alt="Vasto - Trabalhe Conosco" width="20px" />
            </div>
            <div class="box">
              <span>Trabalhe Conosco</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="content-menu">
    <div class="container">
      <modulo-menu classCustom="menu-desktop"></modulo-menu>
    </div>
  </div> 
  <modulo-menu class="col-menu-mobile" classCustom="menu-mobile"></modulo-menu>
</header>
  