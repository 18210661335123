<div class="menu {{classCustom}}">
  <div class="button-open">
    <button type="button" class="menu-open" (click)="_open()">
        <i class="material-icons">menu</i>   
    </button>    
  </div>  
  <div class="close" #close (click)="_open()">  
    <i class="material-icons">close</i>
  </div>   
  <div class="menu-content" #content> 
      <ul>
          <li>
              <a routerLink="/" title="Igreja Greenhouse">
                Home
              </a>    
          </li>
          <li>
            <a href="javascript::void();" title="Quem Somos" (click)="toSection('#section-quem-somos')">   
              Sobre
            </a>    
          </li>
          <li>
            <a href="javascript::void();"  title="Parceiros" (click)="toSection('#section-parceiros')">   
              Parceiros
            </a>    
          </li>
          <li>
              <a href="javascript::void();" title="Contato" (click)="toSection('#section-contato')">
                Contato
              </a>    
          </li>
      </ul>  
  </div> 
</div> 
<div class="overlay" #overlay></div>      
