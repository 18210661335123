import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';

declare var $:any; 

@Component({
  selector: 'modulo-banner-slider',
  templateUrl: './banner-slider.component.html',
  styleUrls: ['./banner-slider.component.scss']
})
export class BannerSliderComponent implements OnInit {

  @Input("data")  data = null;
  @ViewChild("banner") banner:ElementRef;
  public banners       = [];
  public width = null;

  constructor(){}

  /**
   * 
   * Inicializa o banner
   * 
   */
  initBanner(){

    $(this.banner.nativeElement).slick({
      infinite: true,
      autoplay: true,
      dots: false,
      autoplaySpeed: 2500
    });
    $(this.banner.nativeElement).slick('refresh');

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkProduto(banner){

    return "/servicos/"+banner.categoria_apelido+"/"+banner.produto_apelido;

  }
  /**
   * 
   * Retorna o link do produto
   * 
   */
  getLinkCategoria(banner){

    return "/servicos/"+banner.categoria;

  }
  /**
   * 
   * Next
   * 
   */
   prev(){ 

    $(this.banner.nativeElement).slick("slickPrev");  

  }
  /**
   * 
   * Next
   * 
   */
  next(){

    $(this.banner.nativeElement).slick("slickNext"); 

  }
  /**
   * 
   * On 
   * 
   */
   _onResize(){

    this.width = window.innerWidth; 

  }
  /***
   * 
   * Inicializa as Funções
   * 
   * 
   */
  ngOnInit(){
    this.width = window.innerWidth;
  }
  /**
   * 
   * After View loaded
   * 
   */
  ngAfterViewInit(): void {
    this.initBanner();
  }

}
