import { Component, OnInit, Input, ViewChild, AfterViewInit } from '@angular/core';
import { NavigationStart, NavigationEnd,Event as NavigationEvent, Router } from '@angular/router';
import { AppService } from 'src/app/services/app.service';

declare var $:any;

@Component({
  selector: 'modulo-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss']
})
export class MenuComponent implements OnInit,AfterViewInit {

  @Input("classCustom") classCustom:any;
  @Input("mobile") mobile = false;
  @ViewChild("content") content:any; 
  @ViewChild("overlay") overlay:any; 
  @ViewChild("close") close:any; 
  @ViewChild("sub") sub:any; 
  public subMenu  = false;
  public showMenu = null;
  public show     = false;

  constructor(
    public router: Router,
    public app: AppService
  ){ }
  
  /**
   * 
   * Open
   * 
   */
   _open(){

    if(this.show){

      this.content.nativeElement.classList.remove("slide-right");
      this.content.nativeElement.classList.add("slide-left");

      setTimeout(() => {
        this.content.nativeElement.classList.remove("show");
      },200);
      this.overlay.nativeElement.classList.remove("show");
      this.close.nativeElement.classList.remove("show");
      $(".menu-sub-1").removeClass("show");
      $(".menu-sub-2").removeClass("show");
      this.show = false;  

    }else{

      this.content.nativeElement.classList.add("slide-right");

      setTimeout(() => {
        this.content.nativeElement.classList.add("show");
      },200);

      this.content.nativeElement.classList.remove("slide-left"); 
      this.overlay.nativeElement.classList.add("show");
      this.close.nativeElement.classList.add("show");
      this.show = true;

    }
    return false;

  }
  _close(){

    
    this.content.nativeElement.classList.remove("slide-right");
    this.content.nativeElement.classList.add("slide-left");

    setTimeout(() => {
      this.content.nativeElement.classList.remove("show");
    },200);
    this.overlay.nativeElement.classList.remove("show");
    this.close.nativeElement.classList.remove("show");
    $(".menu-sub-1").removeClass("show");
    $(".menu-sub-2").removeClass("show");
    this.show = false;  
    
    return false;

  }
  /**
   * 
   * To section
   * 
   */
   toSection(id:any){

    if(id == "#home"){

      $('html, body').stop().animate({ 
        scrollTop: 0
      }, 500);

    }else{
      
      var top = $(id).offset().top; 
          
      $('html, body').stop().animate({ 
        scrollTop: top-100 
      }, 500);

    }
    this._close();

  }
  /**
   * 
   * 
   */
  _subMenu(){

    this.subMenu = this.subMenu == true ? false : true;

  }
  /**
   * 
   * On Events
   * 
   */
  onEnvets(){

    this.router.events.forEach((event:NavigationEvent) =>{
      
      if(event instanceof NavigationEnd){
        
        if(window.innerWidth < 959){ 
          this.show = true;
          this._open();
        }
          
      }
              
    });


  }
  /**
   * 
   * Show
   * 
   **/
  _show(id){

    if($(id).hasClass("show")){
      $(id).removeClass("show");
    }else{
      $(id).addClass("show");
    }

  }
  /**
   * 
   * Iniciliza 
   * 
   */
  ngOnInit(){}
  /**
   * 
   * After
   * 
   */
  ngAfterViewInit(): void {
    this.onEnvets();
  }

}
