import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'visao-modulo',
  templateUrl: './visao-modulo.component.html',
  styleUrls: ['./visao-modulo.component.scss']
})
export class VisaoModuloComponent implements OnInit {

  @Input("data") data:any         = null;
  @Input("template") template:any = null;
  public photo = null;

  constructor(){}

  /***
   * 
   * Image
   * 
   */
  setImage(){

    this.photo = "/images/modulo/visao/"+this.data.params.imagem;

  }
  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{
    this.setImage();
  }

}
