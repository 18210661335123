import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'modulo-quem-somos',
  templateUrl: './quem-somos.component.html',
  styleUrls: ['./quem-somos.component.scss']
})
export class QuemSomosComponent implements OnInit {

  @Input("data") data:any = null; 
  public parcerias = [];
  public link = null;

  constructor() { }

  /**
   * 
   * Init
   * 
   */
  ngOnInit():void{}

}
