<div class="app-bg">
    <div class="box-left">
        <img src="/images/template/bg-left.svg" alt="Vasto Representações" />
    </div>
    <div class="box-right">
        <img src="/images/template/bg-right.svg" alt="Vasto Representações" />
    </div>
</div>
<div class="app-content"> 
    <modulo-header></modulo-header>
    <router-outlet></router-outlet>
    <footer-partial></footer-partial> 
</div>    
