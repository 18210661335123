<div class="modulo modulo-rede-social">
    <div [ngSwitch]="template">
      <div *ngSwitchCase="null">
        <div class="modulo-titulo" *ngIf="data.mostrar_titulo">
          <h3>{{data.titulo}}</h3>
        </div>
        <div class="modulo-content modulo-content-footer">
          <ul>
            <li>
              <a href="{{data.params.facebook}}" target="__blank" title="Facebook - Brocker Turismo">
                <i class="fab fa-facebook-f"></i>
              </a> 
            </li> 
            <li>
              <a href="{{data.params.instagram}}" target="__blank" title="Instagram - Brocker Turismo">
                <i class="fab fa-instagram"></i>
              </a>  
            </li>
            <li>
              <a href="{{data.params.youtube}}" target="__blank" title="Youtube - Brocker Turismo">
                <i class="fab fa-youtube"></i>
              </a>  
            </li> 
          </ul>  
        </div>  
      </div>
      <div *ngSwitchCase="'footer'" class="template-footer">
        <h3>{{data.titulo}}</h3> 
        <ul>
          <li>
            <a [href]="data.params.facebook" target="_blank" title="Facebook">
              <i class="fa-brands fa-square-facebook"></i>
            </a>
          </li>
          <li>
            <a [href]="data.params.instagram" target="_blank" title="Instagram">
              <i class="fa-brands fa-square-instagram"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>  
</div>